import React from 'react';

import { TplWSidebar as Tpl } from '#Template'
import Form, { Password } from '#Form'
import { useEvalAcl, useFormApiCall } from './common';
import { Typography } from '@mui/material';

const ChpassPage = () => {
	const formDef = {
		callSet: useFormApiCall('profile/chpass'),
	}

	return (
		<Tpl title="MyAccount - Cambio password">
			{ useEvalAcl('profile-edit-password') ? (
				<Form {...formDef}>
					<Password label="Vecchia password" name="oldPassword" required />
					<Password label="Nuova password" name="newPassword" required />
					<Password label="Conferma password" name="confirmPassword" required />
				</Form>
			) : <Typography>Cambio password non abilitato</Typography> }
		</Tpl>
	)
}
export default ChpassPage