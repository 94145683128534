import React, { useState, useEffect, useContext } from 'react';
// import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AvatarMui from '@mui/material/Avatar';
import { useTheme } from './Template'

import APICtx from './api'

// import Button from '@mui/material/Button';
// import ButtonGroup from '@mui/material/ButtonGroup';
// import Tooltip from '@mui/material/Tooltip';

// function BtnItem({ icon, label, linkTo, onClick, show=true }) {
// 	const display = icon ? <FontAwesomeIcon icon={icon} size="lg" /> : label
// 	return show ? (
// 		<Tooltip title={label}>
// 			<Button
// 				component={linkTo ? Link : null}
// 				to={linkTo}
// 				onClick={onClick}
// 			>
// 				{display}
// 			</Button>
// 		</Tooltip>
// 	) : null
// }

export const evalDate = (value) => {
	let dateObj = new Date(value)
	let ret = ("0" + dateObj.getDate()).slice(-2)
	ret += '/' + ("0" + (dateObj.getMonth() + 1)).slice(-2)
	ret += '/' + dateObj.getFullYear()
	return ret
}
export const DisplayDate = ({ value }) => (
	<>
		{evalDate(value)}
	</>
)

export const evalNumber = ({ decimals=2, decimalsSpace=4, value:valueRaw, suffix }) => {
	const multiplier = Math.pow(10, decimalsSpace)
	let value = (valueRaw / multiplier).toFixed(decimals)
	value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
	if(suffix)
		value += ' '+suffix
	return value
}
export const DisplayNumber = (props) => {
	const value = evalNumber(props)
    return (
		<>
			{value}
		</>
    )
}

const isFaIcon = value => typeof(value)==='object' && value.hasOwnProperty('iconName') && value.hasOwnProperty('icon')

export const Avatar = ({ sx, color, children }) => {
	const theme = useTheme()
	const sxCalc = sx || {}
	if(color)
		sxCalc.bgcolor = theme.getColor(color)
	
	if(isFaIcon(children))
		return (
			<AvatarMui sx={sxCalc}>
				<FontAwesomeIcon icon={children} />
			</AvatarMui>
		)
	
	let val = typeof(children)!=='string' ? children.toString() : children
	const strCut = val.split('.')
	val = strCut[0][0] + (strCut.length>1 ? strCut[1][0] : (strCut[0][1] || ''))

	return (
		<AvatarMui sx={sxCalc}>
			{val.toUpperCase()}
		</AvatarMui>
	)
}

export const useEvalUser = () => {
	const api = useContext(APICtx)

	return async uid => {
		// const data = uid ? await api.callAuth('users/get/'+uid) : undefined
		const data = uid ? await api.auth.appUserGet(uid) : undefined
		return data===undefined ? '[Utente sconosciuto]' : data?.displayName || data?.address
	}
}
export const DisplayUser = ({ uid }) => {
	const evalUser = useEvalUser()
	const [ label, setLabel ] = useState('')

	useEffect(() => {
		evalUser(uid).then(setLabel)
	}, [ uid ])
	return (
		<>
			{label}
		</>
    )
}

export const DisplayGroup = ({ gid }) => {
	const api = useContext(APICtx)
	const [ data, setData ] = useState({})

	// const handleRefresh = () => gid ? api.callAuth('groups/get/'+gid).then(setData) : setData(undefined)
	const handleRefresh = () => gid ? api.auth.appGroupGet(gid).then(setData) : setData(undefined)
	useEffect(() => { handleRefresh() }, [ gid ])

	return data===undefined ?
		'[Gruppo sconosciuto]'
		:
		data?.displayName || data?.address
}