import React, { useEffect, useState, useContext } from 'react';
import APICtx, { useApi } from '#api'
import { TplCentralBox as Tpl } from '#Template'
import Login from './Login'
import Sessions from './Sessions'

export const useAddLogin = () => {
	const api = useContext(APICtx)
	return async auth => {
		const ret = await api.call('session/create', auth, { rawData:true })
		if(ret?.data?.key) {
			const curLogins = api.getVar('sessions') || []
			api.setVar('sessions', curLogins.concat(ret.data))
		}
		return ret
	}
}

export const useRmLogin = () =>{
	const api = useContext(APICtx)
	return async uid => {
		const curLogins = api.getVar('sessions') || []
		api.setVar('sessions', curLogins.filter(login => login.uid!==uid))
	}
}

export default function AuthPage({ handleLogin }) {
	const api = useApi()
	const [ sessions, setSessions ] = useState(false)

	const handleRefresh = async () => {
		const sessions = api.getVar('sessions')
		if(sessions && sessions.length) {
			const newSessions = await api.call('session/refresh', { sessions })
			setSessions(newSessions)
			api.setVar('sessions', newSessions)
		}
		else
			setSessions(sessions)
	}
	useEffect(() => {
		handleRefresh()
		const interval = setInterval(handleRefresh, 120*1000)
		return () => clearInterval(interval)
	}, [])

	return (
		<Tpl title="MyAccount - Accesso utente">
			{ sessions && <Sessions list={sessions} handleRefresh={handleRefresh} handleLogin={handleLogin} />}
			<Login handleRefresh={handleRefresh} useAddLogin={useAddLogin} handleLogin={handleLogin} />
		</Tpl>
	)
}