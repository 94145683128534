import React from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
// import AvatarMui from '@mui/material/Avatar';
// import Tooltip from '@mui/material/Tooltip';
import { Typography } from '@mui/material';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button from './Button'
import { Avatar } from './Display'

const Actions = ({ def }) => {
	return def.map((action, itemIdx) => (
		<Button key={itemIdx} {...action} />
	))
}

export const ListItem = ({ avatar, avatarColor, label, sublabel, actions }) => {
	return (
			<Paper sx={{ flexGrow: 1, mb:1, p:1 }}>
				<Grid container spacing={3} sx={{ p:0 }}>
					<Grid item xs sx={{ display:'flex' }}>
						{ Boolean(avatar) && (
							<Avatar color={avatarColor}>
								{avatar}
							</Avatar>
						)}
						<Typography sx={{ pl:1, m:'auto 0' }}>{label}</Typography>
					</Grid>
					{ Boolean(sublabel) && (
						<Grid item xs sx={{ display: { xs:'none', lg:'flex' } }}>
							<Typography sx={{ m:'auto 0' }}>{sublabel}</Typography>
						</Grid>
					)}
					{ Boolean(actions) && (
						<Grid item xs="auto" sx={{ display:'flex' }}>
							<Actions def={actions} />
						</Grid>
					)}
				</Grid>
			</Paper>
	)
}

export function ListItemOld({ avatar, avatarTooltip, children, actions }) {
	// const classes = useStyles()

	return (
		<Grid item xs={12}>
			<Paper sx={{ pl:1 }}>
				<Grid container wrap="nowrap" spacing={1}>
					{ avatar && (
						<Grid item sx={{ pt:1, pb:1 }}>
							<Avatar tooltip={avatarTooltip}>{avatar}</Avatar>
						</Grid>
					)}
					<Grid item xs sx={{ pt:1, pb:1 }}>
						<Box display="flex" alignItems="center" style={{height:'100%'}}>
							{children}
						</Box>
					</Grid>
					{ actions && (
						<Grid item sx={{ p:1 }}>
							{ actions.map((action, idx) => <Button key={idx} {...action} /> ) }
						</Grid>
					)}
				</Grid>
			</Paper>
		</Grid>
	)
}

export default function List({ children, sx }) {
	return children
	// return (
	// 	<Grid container direction="row" spacing={2} sx={sx}>
	// 		{children}
	// 	</Grid>
	// )
}