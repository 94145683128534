import React from 'react';

import { TplWSidebar as Tpl } from '#Template'
import Form, { Input, InputDate, Checkbox } from '#Form'
import { useEvalAcl, useFormApiCall } from './common';

const EmailPage = () => {
	const aclMailVacation = useEvalAcl('profile-edit-mail-vacation')

	const formCallSet = useFormApiCall('profile/email/set')
	const formDef = {
		callGet: useFormApiCall('profile/email/get'),
		callSet: aclMailVacation && formCallSet,
	}

	return (
		<Tpl title="MyAccount - Configurazione email">
			<Form {...formDef}>
				<InputDate label="Dal" name="fromDate" disabled={!aclMailVacation} />
				<InputDate label="Al" name="toDate" disabled={!aclMailVacation} />
				<Input label="Oggetto" name="subject" disabled={!aclMailVacation} />
				<Input label="Testo del messaggio" name="body" multiline disabled={!aclMailVacation} />
				<Checkbox label="Attivo" name="isEnabled" disabled={!aclMailVacation} />
			</Form>
		</Tpl>
	)
}
export default EmailPage