import React from 'react';
// import { useParams } from "react-router-dom";

// import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import Form, { Input } from '#Form'
import { useEvalAcl, useFormApiCall } from './common';

const DetailsPage = () => {
	const aclDisplayName = useEvalAcl('profile-edit-labels')

	const formCallSet = useFormApiCall('profile/details/set')
	const formDef = {
		callGet: useFormApiCall('profile/details/get'),
		callSet: aclDisplayName && formCallSet,
	}

	return (
		<Tpl title="MyAccount - Dettagli profilo">
			<Form {...formDef}>
				<Input label="Nome mostrato" name="displayName" disabled={!aclDisplayName} />
				<Input label="Email primaria" name="email" disabled />
			</Form>
		</Tpl>
	)
}
export default DetailsPage