import React, { useState, useMemo, useContext, useEffect } from 'react';
import { Routes, Route, useNavigate, useParams } from "react-router-dom";

import APICtx from '#api'
import { TplConfig } from '#Template'
import { faUsersCog, faUserTag, faUserShield, faEnvelope } from '@fortawesome/pro-solid-svg-icons'

import AuthPage from '../auth/Page'
import DetailsPage from './Details';
import ChpassPage from './Chpass';
import EmailPage from './Email';
import { AclsCtx, useFormApiCall } from './common'

const ChooseProfilePage = () => {
	const navigate = useNavigate()
	const handleSelectProfile = async session => navigate(session.uid)
	return <AuthPage handleLogin={handleSelectProfile} />
}

const ProfileRouter = () => {
	const api = useContext(APICtx)
	const navigate = useNavigate()
	const { uid } = useParams()
	const [ acls, setAcls ] = useState([])
	const aclsCall = useFormApiCall('profile/acls/get')

	const sessionsUids = api.getVar('sessions').map(session => session.uid)
	const uidValid = useMemo(() => sessionsUids.includes(uid), [ sessionsUids, uid ])
	
	useEffect(() => {
		if(uidValid)
			aclsCall().then(ret => setAcls(ret?.data || []))
	}, [ uid ])

	useEffect(() => {
		if(!uidValid)
			navigate("..", { replace: true })
	}, [ uid, sessionsUids ])

	const tools = [{
		icon: faUsersCog,
		linkTo: '/profile',
	}]
	const menu = [
		{ label:"Dettagli", icon:faUserTag, linkTo:"/profile/"+uid },
		{ label:"Cambia password", icon:faUserShield, linkTo:"/profile/"+uid+"/chpass" },
		{ label:"Email", icon:faEnvelope, linkTo:"/profile/"+uid+"/email" },
	]

	return uidValid ? (
		<TplConfig tools={tools} menu={menu}>
			<AclsCtx.Provider value={acls}>
				<Routes>
					<Route path="chpass" element={<ChpassPage />} />
					<Route path="email" element={<EmailPage />} />
					<Route path="*" element={<DetailsPage />} />
				</Routes>
			</AclsCtx.Provider>
		</TplConfig>
	) : null
}

const MainRouter = () => (
	<Routes>
		<Route index element={<ChooseProfilePage />} />
		<Route path=":uid/*" element={<ProfileRouter />} />
	</Routes>
)
export default MainRouter