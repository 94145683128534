import React, { useState } from 'react'
import { Link } from "react-router-dom"
import Tooltip from '@mui/material/Tooltip'
import Badge from '@mui/material/Badge'
import IconButton from '@mui/material/IconButton'
import LoadingButton from '@mui/lab/LoadingButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { openData } from './helpers/file'

export default function Button({ label, tooltip, icon, iconSize, color, onClick, mkData, linkTo, badgeContent, badgeColor="primary", sx, disabled }) {
	const [ loading, setLoading ] = useState(false)

	const iconBlock = icon ? <FontAwesomeIcon size={iconSize || undefined} icon={icon} /> : undefined

	const handleClick = async event => {
		setLoading(true)
		await onClick?.(event)
		if(mkData)
			openData(await mkData())
		setLoading(false)
	}

	const btnParams = {
		sx, color, disabled,
		onClick:handleClick,
	}
	if(linkTo) {
		btnParams.component = Link
		btnParams.to = linkTo
	}

	const gfxItem = label ? (
		<LoadingButton
			{...btnParams}
			variant="contained"
			startIcon={Boolean(label) && iconBlock}
			loading={loading}
			loadingPosition={label && iconBlock ? 'start' : 'center'}
		>
			{label || iconBlock}
		</LoadingButton>
	) : (
		<IconButton {...btnParams}>
			<Badge badgeContent={badgeContent} color={badgeColor}>
				{iconBlock}
			</Badge>
		</IconButton>
	)
	return tooltip ? <Tooltip title={tooltip} placement="bottom">{gfxItem}</Tooltip> : gfxItem
}