import { useEffect } from 'react';
import { eventOn, eventOff, eventDispatch } from "./eventBus"

export const useMsg = callback => useEffect(() => {
	const cbExec = e => callback(e.detail)
	eventOn('notify', cbExec)
	return () => eventOff("notify", cbExec)
}, [ callback ])

export const msgOk = txt =>
	eventDispatch('notify', {
		message: txt,
		severity: 'success',
	})

export const msgErr = txt =>
	eventDispatch('notify', {
		message: txt,
		severity: 'error',
	})