export const openData = ({ content, type, download }) => {
	let data
	switch(type) {
		case 'text/csv':
			data = content.map(row =>
				row
					.map(col => {
						const str = typeof(col)==='string' ? col : col.toString()
						return '"' + (str.replace(/"/g, '\\"')) + '"'
					})
					.join(';')
			).join('\n')
		break
		case 'application/json':
			data = JSON.stringify(content, null, 2)
		break
		default:
			data = content
	}
	return openBlob({ data, type, download })
}

const isJsonBuffer = val => typeof(val)==='object' && val.type==='Buffer' && Array.isArray(val.data)

const openBlob = ({ data, type, download }) => {
	let buf = isJsonBuffer(data) ? new Uint8Array(data.data).buffer : data
	const blob = new Blob([buf], { type })
	
	const url = window.URL.createObjectURL(blob)
	const link = window.document.createElement('a')
    link.href = url
    // Construct filename dynamically and set to link.download
	if(download)
    	link.download = download
	else
		link.target='_blank'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)

	window.URL.revokeObjectURL(url)
}