import React, { useContext } from 'react';
import { faUnlink, faSignIn } from '@fortawesome/pro-solid-svg-icons'

import APICtx from '#api'
import List, { ListItem } from '#List'
import { useRmLogin } from './Page'

export default function Sessions({ list, handleRefresh, handleLogin }) {
	const api = useContext(APICtx)
	const rmLogin = useRmLogin()

	const handleRemove = async ({ uid, uname }) =>
		api.dialogConfirm({
			text: 'Sei sicuro di voler eseguire il logout dall\'utente "' + uname + '"?',
			onConfirm: async () => {
				await rmLogin(uid)
				await handleRefresh()		
			},
		})

	const mkActions = item => {
		const actions = []
		handleLogin && actions.push({ tooltip:'Accedi', icon:faSignIn, onClick:() => handleLogin(item) })
		actions.push({ tooltip:'Scollega utente', icon:faUnlink, onClick:() => handleRemove(item) })
		return actions
	}

	return (
		<List title="Categorie" sx={{ mb:1 }}>
			{ list.map(item => (
				<ListItem
					key={item.uid}
					avatar={item.uname.split('@')[0]}
					actions={mkActions(item)}
					label={item.uname}
				/>
			))}
		</List>
	)
}