import { useMemo, createContext, useContext } from 'react';
import { useParams } from "react-router-dom";

import APICtx from '#api'
export const AclsCtx = createContext([])

export const useFormApiCall = callTag => {
	const api = useContext(APICtx)
	const { uid } = useParams()
	
	return useMemo(() => {
		const keyScan = api.getVar('sessions')
			.filter(session => session.uid===uid)
			.map(session => session.key)
		if(keyScan && keyScan.length)
			return (params={}) => api.call(callTag, params, {
				rawData: true,
				authKey: keyScan[0],
			})
	}, [ callTag, uid ])
}

export const useEvalAcl = acl => {
	const acls = useContext(AclsCtx)
	return Array.isArray(acls) && acls.includes(acl)
}