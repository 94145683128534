import React, { useContext } from 'react';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { faArrowLeft, faRightToBracket, faRightFromBracket, faBars } from '@fortawesome/pro-solid-svg-icons'

import APICtx from './api'
import { TplConfCtx } from './Template'
import Button from './Button'

const MenuItem = def => (
	<Button color="inherit" {...def} sx={{ ml:1 }} />
)

export default function HeadBar({ title, sidebarWidth = 0, toolbarHeight, sidebarOpen, toggleSidebar }) {
	const api = useContext(APICtx)
	const tplConf = useContext(TplConfCtx)

	const { tools, toolAuth, backTo } = tplConf
	const appbarSX = {
		width: {
			md: `calc(100% - ${sidebarWidth})`,
		}
	}
	const menuBtnSX = {
		mr: 2,
		display: { xs:'block', md:'none' },
	}

	return (
		<AppBar position="fixed" sx={appbarSX} open={sidebarOpen}>
			<Toolbar>
				<Button icon={faBars} color="inherit" sx={menuBtnSX} onClick={toggleSidebar?.(true)} />
				{ backTo && <Button icon={faArrowLeft} color="inherit" sx={{ mr:2 }} linkTo={backTo} /> }
				<Typography variant="h6" component="div" sx={{ flexGrow:1, overflow:'hidden', whiteSpace:'nowrap' }}>
					{title}
				</Typography>
				{ tools && tools.map((item, idx) => <MenuItem key={idx} {...item} />) }
				{ toolAuth && !api.chkAuth() && <MenuItem key="_authLogin" tooltip="Login" icon={faRightToBracket} onClick={api.authLogin} /> }
				{ toolAuth && api.chkAuth() && <MenuItem key="_authLogout" tooltip="Logout" icon={faRightFromBracket} onClick={api.authLogout} /> }
			</Toolbar>
		</AppBar>
	)
}
