import React, { useEffect, useMemo, useState } from "react"
import superagent from "superagent"

import setupFull from './setup.json'
import conf from '../conf.json'
import NatsLib from "./helpers/nats"
import { openData } from "./helpers/file"
import { rndStr, hashStr } from "./helpers/crypto"
import { msgOk, msgErr } from './helpers/notify'

const APICtx = React.createContext(null)
export default APICtx

const NODE_ENV = process.env.REACT_APP_NODE_ENV || 'development'
export const confApi = conf.api[NODE_ENV]
const authLoopTime = 120*1000
const setup = setupFull[NODE_ENV]

const nats = new NatsLib({
	servers: setup.natsServers,
	jwt: confApi.natsJwt,
	nkey: confApi.natsNkey,
	env: NODE_ENV,
})

export const useQuery = () =>
	useMemo(() => {
		const params = new Proxy(new URLSearchParams(window.location.search), {
			get: (searchParams, prop) => searchParams.get(prop),
		});
		return params
	}, [ window.location.search ])

const dialogConfirm = ({ text, onConfirm, onCancel }) => {
	if(window.confirm(text) === true)
		onConfirm && onConfirm()
	else
		onCancel && onCancel()
}

const callRaw = async (tag, data={}, opts={}) => {
	if(NODE_ENV!=='production')
		console.log('CALL-SRV', tag, data)
	if(!opts.authKey)
		opts.authKey = getVar('_auth_key')
	return nats.requestSrv({ tag, data, opts })
}
const callAuthInfo = async ({ type, uid, gid, acl }) => {
	const ret = await nats.requestAuth({
		tag: 'info',
		data: { type, uid, gid, acl },
		opts: {
			authKey: getVar('_auth_key'),
		}
	})
	if(NODE_ENV!=='production')
		console.log('CALL-AUTH-INFO', { type, uid, gid, acl }, ret)
	if(ret.error)
		ret.message && msgErr(ret.message)
	else {
		ret.message && msgOk(ret.message)
		return ret?.data
	}
}

const storageUpload = async (uploadToken, file) => {
	const ret = await superagent.put(setup.storage.uploadAddress + file.name)
		.set('Authorization', 'Bearer ' + uploadToken)
		.query({ mimetype: file.type })
		.set('Content-Type', 'application/octet-stream')
		.send(file)
	return ret.text
}
const storageDownload = async (downloadToken, showPreview) => {
	const ret = await superagent.get(setup.storage.downloadAddress)
		.set('Authorization', 'Bearer ' + downloadToken)
		.responseType('arraybuffer')
	openData({
		content: ret.body,
		type: ret.get('Content-Type'),
		download: showPreview ? undefined : ret.get('Content-Filename'),
	})
}


const setVar = (key, value) =>
	value===undefined ? localStorage.removeItem(key) : localStorage.setItem(key, JSON.stringify(value))
const getVar = key => {
	const value = localStorage.getItem(key)
	return value ? JSON.parse(value) : undefined
}

export function useApi() {
	const [ isReady, setIsReady ] = useState(false)
	const [ authInfo, setAuthInfo ] = useState(null)
	const appAuth = useQuery()._app

	const call = async (tag, params={}, { rawData, suppressErrorMessage, ...opts } = {}) => {
		const ret = await callRaw(tag, params, opts)
		if(ret.error) {
			switch(ret.error) {
				case 'unauthorized':
					msgErr('Sessione non valida o scaduta')
					authLogout()
				break
				default:
					!suppressErrorMessage && ret.message && msgErr(ret.message)
			}
			if(rawData)
				return ret 
		}
		else {
			ret.message && msgOk(ret.message)
			return rawData ? ret : ret?.data
		}
	}
	
	const openFile = async ({ tag, params, opts, download=null }) =>
		openData(
			{ ...(await call(tag, params, opts)), download }
		)

	const chkAuth = (acl) => authInfo && ( !acl || (authInfo?.acls && authInfo.acls.includes(acl)) )
	const chkAuthAny = acls => acls.some(acl => acl && chkAuth(acl))
	const chkAuthAll = acls => acls.every(acl => acl && chkAuth(acl))
	const authLogin = () => {
		const nonce = rndStr(12)
		setVar('_auth_nonce', nonce)
		return Boolean(setup.urlSSO) && (
			window.location.href = setup.urlSSO + '/?_app=' + window.btoa(
				JSON.stringify({
					app: confApi.appId,
					nonce,
					addr: window.location.origin,
				})
			)
		)
	}
	const authRefresh = async () => {
		const key = getVar('_auth_key')
		const payload = key ? await call('_/authEval', { token:key }) : null
		if(key && !payload)
			setVar('_auth_key', undefined)
		setAuthInfo(payload || null)
	}
	const authSet = async rawData => {
		const data = JSON.parse(window.atob(rawData))
		const nonceCmp = getVar('_auth_nonce')
		if(nonceCmp && hashStr(nonceCmp)===data.nhash) {
			const payload = await call('_/authEval', { token:data.key })
			if(!payload || nonceCmp!==payload.nonce)
				msgErr('Impossibile eseguire il login')
			else {
				setVar('_auth_nonce', undefined)
				setVar('_auth_key', data.key)
				setAuthInfo(payload)
			}
		}
		else
			return authRefresh()
	}
	const authLogout = () => {
		setVar('_auth_key', undefined)
		setAuthInfo(null)
	}
	const isAuth = useMemo(() => {
		return Boolean(authInfo)
	}, [ authInfo ])

	useEffect(() => {
		const firstOp = appAuth ? authSet(appAuth) : authRefresh()
		firstOp.then(() => setIsReady(true))

		const interval = setInterval(authRefresh, authLoopTime)
		return () => clearInterval(interval)
		// eslint-disable-next-line
	}, [ appAuth ])

	const auth = useMemo(() => ({
		login:authLogin, logout:authLogout,
		chk:chkAuth, chkAny:chkAuthAny, chkAll:chkAuthAll,
		uid: authInfo?.uid || null,
		groups: authInfo?.grps || [],
		// appUserList: (p={}) => callAuthInfo('users/list', p),
		appUserList: (p={}) => callAuthInfo({ ...p, type:'users' }),
		appUserGet: uid => callAuthInfo({ type:'user', uid }),
		usersAsOptions: p => auth.appUserList(p).then(uids =>
			Promise.all( uids.map(uid =>
					auth.appUserGet(uid).then(user => ({ label:user.displayName || user.address, value:uid, address:user.address }))
			))
		),
		// appGroupList: (p={}) => callAuthInfo('groups/list', p),
		appGroupList: (p={}) => callAuthInfo({ ...p, type:'groups' }),
		// appGroupGet: gid => callAuthInfo('groups/get/'+gid),
		appGroupGet: gid => callAuthInfo({ type:'group', gid }),
		groupsAsOptions: p => auth.appGroupList(p).then(gids =>
			Promise.all( gids.map(gid =>
					auth.appGroupGet(gid).then(group => ({ label:group.displayName || group.address, value:gid, address:group.address }))
			))
		),
	}), [ authInfo ])

	return {
		callRaw, call, openFile,
		isReady,
		msgOk, msgErr, dialogConfirm,
		setVar, getVar,
		isAuth, chkAuth, chkAuthAny, chkAuthAll, authLogin, authLogout,
		auth,
		NODE_ENV, setup,
		storage: { upload:storageUpload, download:storageDownload },
	}
}