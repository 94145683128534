import React from 'react';

import Box from '@mui/material/Box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faExclamationTriangle, faExclamationCircle, faInfoCircle, faCheck, faCircleNotch } from '@fortawesome/pro-solid-svg-icons'

const levels = {
	'danger': { icon:faExclamationTriangle, color:'error.main' },
	'warning': { icon:faExclamationCircle, color:'warning.main' },
	'info': { icon:faInfoCircle, color:'info.main' },
	'success': { icon:faCheck, color:'success.main' },
	'syncing': { icon:faCircleNotch, color:'success.main', extras:{spin:true} },
}

export default function StatusIcon({ level }) {
	const cur = levels[level]
	return (
		<Box sx={{ color:cur.color, display:'inline' }}>
			<FontAwesomeIcon icon={cur.icon} size="lg" {...cur.extras} />
		</Box>
	)
}