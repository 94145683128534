import React from 'react';
import { useApi, useQuery } from '#api'
import { hashStr } from '#crypto'

import AuthPage from './auth/Page'

export default function LoginPage() {
	const api = useApi()
	const appToken = useQuery()._app

	const handleAppLogin = async session => {
		const { app, nonce, addr } = JSON.parse(window.atob(appToken))
		const key = await api.call('session/mkAppKey', { sessionKey:session.key, app, nonce })
		key && (
			window.location.href = addr + '/?_app=' + window.btoa(
				JSON.stringify({ key, nhash:hashStr(nonce) })
			)
		)
	}

	return appToken ? <AuthPage handleLogin={handleAppLogin} /> : null
}