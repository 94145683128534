import React, { useState } from 'react';
import Typography from '@mui/material/Typography';

import Form, { Input, Password, Checkbox } from '#Form'
import { useAddLogin } from './Page'

export default function Login({ handleRefresh, handleLogin }) {
	const addLogin = useAddLogin()
	const onSubmit = async (auth) => {
		const ret = await addLogin(auth)
		if(!ret?.error)
			handleLogin ? handleLogin(ret.data) : handleRefresh()
		return ret
	}

	const formDef = {
		submitLabel: 'Login',
		onSubmit,
	}

	return (
		<Form {...formDef}>
			<Typography variant="h5" gutterBottom>Nuovo accesso</Typography>
			<Input label="Username" name="username" required />
			<Password label="Password" name="password" required />
			<Checkbox label="Memorizza l'accesso su questo dispositivo" name="remember" />
		</Form>
	)
}