import React, { useContext, useMemo } from 'react';
import FormHelperText from '@mui/material/FormHelperText';

import APICtx from './api'

export const FormCtx = React.createContext({})


export const dataExtract = (data, path) => {
	const step = path.shift()
	if(data?.[step])
		return path.length ? dataExtract(data[step], path) : data[step]
	return ''
}
export const dataPatch = (data, path, value) => {
	if(!path.length)
		return value
	const step = path.shift()
	if(!data || typeof(data)!=='object')
		data = {}
	data[step] = dataPatch(data[step], path, value)
	return data
}

export const mkCallPath = (def, forceAbsolute) => {
	const path = Array.isArray(def) ? def.filter(Boolean).join('/') : def
	return (forceAbsolute && path[0]!=='/' ? '/' : '') + path
}

export const useCallFunction = (def) => {
	const api = useContext(APICtx)
	return useMemo(() => {
		if(def) {
			if(typeof(def)==='function')
				return def
			else
				return (params={}) => api.call(mkCallPath(def), params, {
					rawData: true,
				})
		}
	}, [ def ])
}

export const useData = name => {
	const ctx = useContext(FormCtx)
	// console.log('form-get', ctx.data, name)
	return dataExtract(ctx.data, name.split('.'))
}
export const useSetData = name => {
	const ctx = useContext(FormCtx)
	return value => {
		const clonedData = ctx.data ? JSON.parse(JSON.stringify(ctx.data)) : {}
		const newData = dataPatch(clonedData, name.split('.'), value)
		// console.log('form-set', ctx.data, name, value, newData)
		ctx.setData(newData)
		return value
	}
}
export const useErrors = name => {
	const ctx = useContext(FormCtx)
	// console.log('form-err', ctx.errors, name)
	return ctx.errors && ctx.errors[name] && ctx.errors[name].join(', ')
}

//GFX elements
export const ErrorHelper = ({ name }) => {
	const error = useErrors(name)
	return Boolean(error) && <FormHelperText error={true}>{error}</FormHelperText>
}