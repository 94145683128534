import { connect, JSONCodec, credsAuthenticator } from "nats.ws"
const jsonCodec = JSONCodec()

const REQ_TIMEOUT = 10000

const branchesSrv = {
	production: 'prod',
	staging: 'stag',
	development: 'dev',
}
const branchesSys = {
	production: 'prod',
	staging: 'prod',
	development: 'stag',
}

const mkAuthenticator = ({ jwt, nkey }) => {
	const creds = `
-----BEGIN NATS USER JWT-----
${jwt}
------END NATS USER JWT------
-----BEGIN USER NKEY SEED-----
${nkey}
------END USER NKEY SEED------
	`
	return credsAuthenticator(new TextEncoder().encode(creds))
}

function NatsLib({ servers, jwt, nkey, env='development' }) {
	let nc
	const authenticator = mkAuthenticator({ jwt, nkey })

	const srvEnvTag = branchesSrv[env]
	const sysEnvTag = branchesSys[env]
	// const sysEnvTag = env==='development' ? 'dev' : 'z'
	const calcSrvSubject = tag => srvEnvTag + '.srv.' + (tag.split('/').join('.'))
	const calcAuthSubject = tag => sysEnvTag + '.auth.' + (tag.split('/').join('.'))

	const getNC = async () => {
		if(!nc)
			nc = await connect({
				servers,
				authenticator,
				waitOnFirstConnect: true,
				maxReconnectAttempts: -1,
				pingInterval: 100000,
				inboxPrefix: 'ui._INBOX',
			})
		return nc
	}

	const calcAnswer = raw => {
		const payload = jsonCodec.decode(raw)
		if(payload.error)
			console.error('API-ERROR', payload)
		return payload
	}

	const request = async ({ subject, payload }) => {
		const nc = await getNC()
		const { data:retPayloadRaw } = await nc.request(subject, jsonCodec.encode(payload), { timeout:REQ_TIMEOUT })
		return calcAnswer(retPayloadRaw)
	}

	this.requestSrv = async ({ tag, data={}, opts={} }) => {
		const subject = calcSrvSubject(tag)
		const jwt = opts?.authKey
		const payload = { data, jwt }
		return request({ subject, payload })
	}

	this.requestAuth = async ({ tag, data={}, opts={} }) => {
		const subject = calcAuthSubject(tag)
		const appToken = opts?.authKey
		const payload = { ...data, appToken }
		return request({ subject, payload })
	}
}
export default NatsLib