import React, { useEffect } from 'react';
import { Routes, Route, useNavigate } from "react-router-dom";

import APICtx, { useApi } from '#api'
import Screen from '#Template'

import LoginPage from './LoginPage'
import ProfilesRouter from './profile/Router'

const Router = () => (
	<Routes>
		<Route path="/login" element={<LoginPage />} />
		<Route path="/profile/*" element={<ProfilesRouter />} />
		<Route path="/" element={<DefaultPage />} />
	</Routes>
)

const DefaultPage = () => {
	const navigate = useNavigate()
	useEffect(() => navigate('/profile', { replace: true }), [])
	return null
}

export default function App() {
	const api = useApi()

	return (
		<APICtx.Provider value={api}>
			<Screen>
				<Router />
			</Screen>
		</APICtx.Provider>
	)
}
